<template>
  <div>
    <div class="search-list-view">
      <div>
        <van-list v-model="loading" :finished="finished" @load="onLoad" :offset="offset" >
          <div v-for="(item,key) in list" :key="key" :title="item" class="search-list" v-on:click="selectItem(item)" >
            <div>
              <svg t="1634634887606" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4168" width="200" height="200" > <path d="M864 64H160c-35.296 0-64 28.704-64 64v768c0 35.296 28.704 64 64 64h704c35.296 0 64-28.704 64-64V128c0-35.296-28.704-64-64-64zM256 128h512v224H256V128zM160 896V128h32v288h640V128h32l0.064 768H160z" p-id="4169" fill="#5ba8dd" ></path> <path d="M328 192h64v96h-64zM488 192h64v96h-64zM648 192h64v96h-64zM605.152 480h-135.136L352 647.488l160 40.576-82.88 169.536L672 630.208l-160-24.16z" p-id="4170" fill="#5ba8dd" ></path>
              </svg>
            </div>
            <div>
              <p>{{ item.accountNumber }}</p>
              <span>{{ item.city }}  <span>{{ item.remark }}</span></span>
            </div>
            <van-swipe-cell :before-close="beforeClose" :stop-propagation="true">
              <div>
                <p v-if="item.type == '0'">国家电网</p>
                <p v-if="item.type == '1'">南方电网</p>
                <p v-if="item.type == '2'">中国燃气</p>
                <p v-if="item.type == '3'">华润燃气</p>
                <p v-if="item.type == '4'">港华燃气</p>
                <span>{{ item.addTime }}</span>
              </div>
              <template #right>
                <van-button square type="danger" text="删除" @click="deleteItem(key)"/>
                <!-- <van-button square type="primary" text="选择" @click="selectItem(item)"/> -->
              </template>
            </van-swipe-cell>
          </div>
          <div class="add-account-btn" v-on:click="$router.push('/addAccountNumber')" @click.stop="doThis" >
            添加账户
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import storage from "../utils/Storage";
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      phoneNumberCode: "",
      offset: "-10",
      fromPath: null,//存储页面路径
    };
  },
  //获取上一个页面的路由
   beforeRouteEnter (to, from, next) {
    next(vm => {
      console.log(from, 'from')
      //  这里的vm指的就是vue实例，可以用来当做this使用
      vm.fromPath = from.path  //获取上一级路由的路径
    })
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#f5f5f5");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  created(){
    
  },
  methods: {
    deleteItem(key){
        this.$dialog.confirm({
          message: '确定删除吗？',
        }).then(() => {
          this.axios.delete(`/app/accountnumber/del/${this.list[key].id}`, {})
          .then((response) => {
            this.list.splice(key,1);
            storage.deleteByKey(this.fromPath);
          }).catch((error) => {

          });
        });
    },
    selectItem(obj) {
      console.log("-----selectItem");
      storage.setObjOpsValue(this.fromPath, obj);
      this.$router.back();
    },
    onLoad() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.axios.post("/app/accountnumber/list", {}).then((response) => {
          this.list = [...this.list, ...response];
          this.loading = false;
          this.finished = true;
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    beforeClose(){
      console.log("beforeClose");
    }
  },
};
</script>

<style scoped>
.add-account-btn {
  width: 95%;
  height: 45px;
  line-height: 45px;
  margin: 15px auto 0;
  text-align: center;
  color: #ffffff;
  background: #00ef83;
  border-radius: 5px;
}
.img-icon {
  height: 20px;
}
.search-list-view {
  min-height: 85vh;
  background: #ffffff;
  margin: 10px;
  border-radius: 20px;
  padding: 15px;
  height: 100%;
}
.search-list-view .search-list {
  display: flex;
  margin: 20px 0;
}
.search-list-view .search-list div {
  vertical-align: middle;
}
.search-list-view .search-list div p {
  vertical-align: middle;
  margin: 3px 0;
}
.search-list-view .search-list div:nth-child(2) {
  margin-left: 15px;
}
.search-list-view .search-list div:nth-child(2) span,
.search-list-view .search-list div:nth-child(3) span {
  font-size: 13px;
  color: #cecece;
}
.search-list-view .search-list div:nth-child(3) {
  position: absolute;
  right: 20px;
}
.search-list-view .search-list div:nth-child(3) p {
  color: #2bd67e;
  text-align: right;
}
</style>
